<template>
  <div class="page flex-col" :style="{height: height}">
    <div class="group_1 flex-col">
      <img
        class="image_1"
        referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNGba06ec0c2d5f371621be5558fd135843.png"
      />
      <div class="text-wrapper_1 flex-col">
        <span class="text_1">
          {{lang === 't' ? '上鼎新聞動態':'上鼎新闻动态'}}
        </span>
        <span class="text_2">
          {{lang === 't' ? '最新動態發展，最新新聞資訊，精彩內容盡收眼底。':'最新动态发展，最新新闻资讯，精彩内容尽收眼底。'}}</span>
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="group_3 flex-row">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/logo.png"
        />

        <span class="text_3" @click="$router.push({path:'/index'})">{{lang === 't' ? '關於我們':'关于我们'}}</span>
        <span class="text_4" @click="$router.push({path:'/market'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</span>
        <span class="text_5" @click="$router.push({path:'/features'})">{{lang === 't' ?'業務介紹': '业务介绍' }} </span>
        <span class="text_6" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</span>
        <span class="text_7" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</span>

        <span class="text_8" :class="{show: show}" @click="showLang">
            {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                <p class="trans" @click="changeLan('t')">繁体中文</p>
                <p  @click="changeLan('s')" >简体中文</p>
              </div>
          </span>
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
        />
      </div>
      <div class="group_5 flex-col">
        <div class="text-wrapper_2 flex-row"><span class="text_9">
          {{lang === 't' ? '最新資訊':'最新资讯'}}
          </span></div>
        <div class="block_1 flex-row"><div class="group_6 flex-col"></div></div>
        <div class="block_2 flex-row justify-start">
          <div class="box_1 flex-row" @click="gotoDetail2">
            <div class="image-text_1 flex-col justify-between">
              <img
                class="image_3"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNG86dd468c62f164068d49af71c0c24acc.png"
              />
              <span class="text-group_1">
          <!-- {{lang === 't' ? '上鼎國際亮相中國新興媒體產業融合發展大會 人工智慧加速金融創新應用':'上鼎国际亮相中国新兴媒体产业融合发展大会 人工智能加速金融创新应用'}} -->
                {{lang === 't' ? '上鼎國際與美國上市公司正式達成全面戰略合作協議':'上鼎国际与美国上市公司正式达成全面战略合作协议'}}
                </span>
            </div>
          </div>
          <!-- <div class="box_2 flex-row">
            <div class="image-text_2 flex-col justify-between">
              <img
                class="image_4"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNGb305d09c7ded8dd888e3a655f9a57019.png"
              />
              <span class="text-group_2">
                {{lang === 't' ? '上鼎国际与美国上市公司正式达成全面战略合作协议':'上鼎國際與美國上市公司正式達成全面戰略合作協議'}}
                </span>
            </div>
          </div> -->
          <div class="box_3 flex-row" @click="gotoDetail">
            <div class="image-text_3 flex-col justify-between">
              <img
                class="image_5"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNG71b4dbb78a483b8f8d61b2d63f2eeeaf.png"
              />
              <span class="text-group_3">
          {{lang === 't' ? '上鼎國際與美國上市公司達成合作':'上鼎国际与美国上市公司达成合作'}}
                <!-- {{lang === 't' ? '11.11成交額再創新高，上鼎積累的科技力量如何於千行百業發揮作用？':'11.11成交额再创新高，上鼎积累的科技力量如何于千行百业？'}} -->
                </span>
            </div>
          </div>
        </div>
      </div>
      <span class="text_10">
        {{lang === 't' ? '新聞動態':'新闻动态'}}
        </span>
      <div class="group_7 flex-col"></div>
      <div class="group_8 flex-row" @click="gotoDetail2">
        <span class="text_11">12/</span>
        <span class="paragraph_1">
          08月
          <br />
          2024
        </span>
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_12">{{lang === 't' ? '上鼎國際與美國上市公司正式達成全面戰略合作協議':'上鼎国际与美国上市公司正式达成全面战略合作协议'}}</span>
          <span class="text_13">
            {{lang === 't' ? '香港上鼎人工智能有限公司已經跟美國上市公司簽署合作協議，這一里程碑式的合作將推動雙方在全球範圍內的業務協同與創新發展。雙方將攜手共進，共用資源，深化合作領域，共同探索市場新機遇，為雙方股東及社會各界創造更大的價值。':'香港上鼎人工智能有限公司已经跟美国上市公司签署合作协议，这一里程碑式的合作将推动双方在全球范围内的业务协同与创新发展。双方将携手共进，共用资源，深化合作领域，共同探索市场新机遇，为双方股东及社会各界创造更大的价值。'}}
          </span>
        </div>
      </div>
      <div class="group_8 flex-row" @click="gotoDetail">
        <span class="text_11">06/</span>
        <span class="paragraph_1">
          08月
          <br />
          2024
        </span>
        <div class="text-wrapper_3 flex-col justify-between">
          <span class="text_12">{{lang === 't' ? '上鼎國際與美國上市公司達成合作':'上鼎国际与美国上市公司达成合作'}}</span>
          <span class="text_13">
            {{lang === 't' ? '經過公司管理層與美國上市公司的多次深入溝通，雙方已正式簽署合作意向書，標誌着合作關係的正式確立。同時，我們已順利通過盡職調查，確保合作基礎穩固。目前，雙方團隊正緊密配合，合同內容的每個細節都經過精心雕琢，現已全部擬定完畢，將在8月15日簽訂合作協議。這一里程碑式的合作，不僅彰顯了我們公司國際化戰略的堅定決心，更為我們共同開創更加輝煌的業績奠定了堅實的基礎。':'经过公司管理层与美国上市公司的多次深入沟通，双方已正式签署合作意向书，标志着合作关系的正式确立。同时，我们已顺利通过尽职调查，确保合作基础稳固。目前，双方团队正紧密配合，合同内容的每个细节都经过精心雕琢，现已全部拟定完毕，将在8月15日签订合作协议。这一里程碑式的合作，不仅彰显了我们公司国际化战略的坚定决心，更为我们共同开创更加辉煌的业绩奠定了坚实的基础。'}}
          </span>
        </div>
      </div>
      <!-- <div class="group_14 flex-row">
        <div class="box_4 flex-col"><div class="group_15 flex-col"></div></div>
        <div class="text-wrapper_9 flex-col"><span class="text_29">1</span></div>
        <div class="text-wrapper_10 flex-col"><span class="text_30">2</span></div>
        <div class="text-wrapper_11 flex-col"><span class="text_31">3</span></div>
        <div class="text-wrapper_12 flex-col"><span class="text_32">...</span></div>
        <div class="text-wrapper_13 flex-col"><span class="text_33">9</span></div>
        <div class="box_5 flex-col"><div class="section_3 flex-col"></div></div>
      </div> -->
      <div class="group_16 flex-row">
        <div class="text-wrapper_14 flex-col justify-between">
          <span class="text_34">{{lang === 't'  ?'快速鏈接' : '快速链接'}}</span>
          <span class="paragraph_7">
            <p class="txt" @click="$router.push({path:'/index'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
              <p class="txt" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
              <p class="txt" @click="$router.push({path:'/features'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
          </span>
        </div>
        <div class="group_17 flex-col">
          <span class="text_35">公司地址</span>
          <span class="text_36">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_4 flex-col justify-between">
            <span class="text_37">联系方式</span>
            <span class="text_38">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGb36bb80c676d7b4c8778e245fd0d00e9.png"
        />
        <div class="group_18 flex-col justify-between">
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGa0d8e41262f80cbf5451739b9a4bee12.png"
          />
          <div class="image-text_4 flex-row justify-between" @click="showLang">
            <span class="text-group_5" :class="{show: show}">
                {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                  <p class="trans" @click="changeLan('t')">繁体中文</p>
                  <p  @click="changeLan('s')" >简体中文</p>
                </div>
              </span>
          
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG4fe04a52f00f13adbbcc5eac12de33bb.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const num = 3; // 新闻数  此页面搜 group_8
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: false,
      height: (64 + num*3.02) + 'rem',
    };
  },
  methods: {
    showLang() {
      this.show = !this.show
    },
    changeLan(t) {
      window.localStorage.setItem('lang', t);
      window.location.reload();
    },
    gotoDetail() {
      this.$router.push({path: '/news_detail'})
    },
    gotoDetail2() {
      this.$router.push({path: '/news_detail2'})
    },
  },
 
  created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.$router.push({path: '/news_mobile'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />