<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="nav-bar_1 flex-row" :class="{black: isBlackTop}">
        <div class="group_1 flex-col">
          <div class="image-wrapper_1 flex-row justify-between">
            <img
              class="icon_1"
              referrerpolicy="no-referrer"
              @click="showSlider"
              src="./assets/img/MasterDDSSlicePNG0d956aa7ed8e338a0078ca75fb8e3c13.png"
            />
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
          </div>
        </div>
        <div class="group_2 flex-col justify-between">
          <div class="group_3 flex-col"></div>
          <div class="group_4 flex-col"></div>
        </div>
      </div>
      <div class="box_2 flex-col"></div>
    </div>
    <div class="box_3 flex-col">
      <span class="text_1">{{lang === 't' ? '精英團隊':'精英团队'}}</span>
      <div class="section_1 flex-col"></div>
      <span class="paragraph_1">
        {{lang === 't' ? '我們匯集了一支由數據專家、資深工程師和數學精英組成的頂尖團隊。我們的團隊成員具備深厚的學術背景和豐富的行業經驗，擅長將理論知識與實際應用相結合，開發出創新的解決方案。':'我们汇集了一支由数据专家、资深工程师和数学精英组成的顶尖团队。我们的团队成员具备深厚的学术背景和丰富的行业经验，擅长将理论知识与实际应用相结合，开发出创新的解决方案。'}}
        <br />
        {{lang === 't' ? '在數據專家方面，我們擁有多才多藝的數據科學家和分析師，精通數據挖掘、機器學習和統計分析，能夠從大數據中提煉出有價值的見解和預測模型。':'在数据专家方面，我们拥有多才多艺的数据科学家和分析师，精通数据挖掘、机器学习和统计分析，能够从大数据中提炼出有价值的见解和预测模型。'}}

        <br />
        {{lang === 't' ? '此外，我們公司引以為傲的是數學精英隊伍，他們在數學建模、優化理論和算法設計方面具備卓越的能力。他們的研究和開發推動了我們在復雜問題求解和算法優化方面的領先地位。':'此外，我们公司引以为傲的是数学精英队伍，他们在数学建模、优化理论和算法设计方面具备卓越的能力。他们的研究和开发推动了我们在复杂问题求解和算法优化方面的领先地位。'}}
        <br />
      </span>
    </div>
    <div class="box_4 flex-col">
      <div class="section_2 flex-row">
        <!-- <span class="text_2">快速链接</span> -->
        <span class="text_3">公司地址</span>
        <!-- <div class="image-text_1 flex-row justify-between">
          <span class="text-group_1">简繁切换</span>
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
          />
        </div> -->
      </div>
      <div class="section_3 flex-row">
        <!-- <span class="paragraph_2">
          关于我们
          <br />
          业务介绍
          <br />
          团队介绍
          <br />
          国际市场
        </span> -->
        <div class="box_5 flex-col justify-between">
          <span class="text_4">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_2 flex-col justify-between">
            <span class="text_5">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
            <span class="text_6">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <!-- <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG49943e02bfbfa19e3901d302d72cfe2f.png"
        />
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG7a78523d690336a9fd29835e0a7e07d8.png"
        /> -->
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="image-wrapper_2 flex-col justify-center align-center">
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGfde8970f1d9a523665cefb8a7b3d961a.png"
        />
      </div>
      <div class="box_7 flex-col">
        <span class="text_7">{{lang === 't' ? '董事長簡介':'董事长简介'}}</span>
        <div class="box_8 flex-col"></div>
        <span class="paragraph_3">
          {{lang === 't' ? '香港上鼎人工智能科技有限公司的創始人史鵬勇，是一位資深的IT軟件研發工程師及大數據模型架構師。他在IT軟件研發、人工智能領域，以及量化基金數據大模型領域深耕多年，對全球二級市場及交易大數據模型領域有著豐富的研究與實踐經驗。':'香港上鼎人工智能科技有限公司的创始人史鹏勇，是一位资深的IT软件研发工程师及大数据模型架构师。他在IT软件研发、人工智能领域，以及量化基金数据大模型领域深耕多年，对全球二级市场及交易大数据模型领域有着丰富的研究与实践经验。'}}
          <br />
          {{lang === 't' ? '作為公司的領航者，史鵬勇率領著一支由多學科精英組成的IT軟件研發工程師專家團隊。這支團隊匯聚了計算機學科專家、數學領域精英、軟件工程師及產品經理等人才。史鵬勇憑借其卓越的領導力和深厚的專業知識，引領團隊在人工智能技術的研究與應用上取得了顯著成就。 ':'作为公司的领航者，史鹏勇率领着一支由多学科精英组成的IT软件研发工程师专家团队。这支团队汇聚了计算机学科专家、数学领域精英、软件工程师及产品经理等人才。史鹏勇凭借其卓越的领导力和深厚的专业知识，引领团队在人工智能技术的研究与应用上取得了显著成就。'}}
          <br />
          {{lang === 't' ? '公司所有交易均通過計算機代碼自動執行，全面實現了自動量化交易管理，有效支持了大規模數量賬戶的自動化管理。':'公司所有交易均通过计算机代码自动执行，全面实现了自动量化交易管理，有效支持了大规模数量账户的自动化管理。'}}
        </span>
      </div>
    </div>
    <div class="slider_bg" :class="show === null ? '' : show=== false ? 'hide': 'show'" @click="showSlider"></div>
    <div class="slider-list" :class="show === null ? '' : show=== false ? 'hide': 'show'">
      <div class="close" @click="showSlider"></div>
      <p class="txt" @click="$router.push({path:'/index_mobile'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
      <p class="txt" @click="$router.push({path:'/news_mobile'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
      <p class="txt" @click="$router.push({path:'/features_mobile'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/team_mobile'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/market_mobile'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
      <div class="lang" @click="changeLan">简体 ｜ 繁体</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: null,
      isBlackTop: false,
    };
  },
  methods: {
    showSlider() {
      this.show = !this.show
    },
    // changeLan(t) {
    //   window.localStorage.setItem('lang', t);
    //   window.location.reload();
    // },
       changeLan() {
        const lang = window.localStorage.getItem('lang')
        window.localStorage.setItem('lang', lang === 't' ? 's': 't');
        window.location.reload();
    },
     resetFontSize() {
        const size = (document.documentElement.clientWidth / 1080) * 37.5;
        document.documentElement.style.fontSize = size + 'px';
    },
    onScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 130) {
        this.isBlackTop = true
      } else {
        this.isBlackTop = false
      }
    },
  },
  created() {
  // reset root font size on page show or resize
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.addEventListener('pageshow', this.resetFontSize);
    window.addEventListener('resize', this.resetFontSize);
    window.addEventListener('scroll', this.onScroll);
  } else {
    this.$router.push({path: '/team'})
  }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />