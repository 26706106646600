<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="group_1 flex-col">
        <div class="group_2 flex-col">
          <div class="nav-bar_2 flex-row" :class="{black: isBlackTop}">
            <div class="section_3 flex-col">
              <div class="image-wrapper_3 flex-row justify-between">
                <img
                @click="showSlider"
                  class="icon_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/MasterDDSSlicePNG0d956aa7ed8e338a0078ca75fb8e3c13.png"
                />
                <img
                  class="image_1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/logo.png"
                />
              </div>
            </div>
            <!-- <div class="section_4 flex-col justify-between">
              <div class="group_3 flex-col"></div>
              <div class="group_4 flex-col"></div>
            </div> -->
          </div>
          <div class="text-wrapper_1 flex-col">
            <span class="paragraph_1">
              {{lang === 't'  ?'上鼎科技  全球卓越' : '上鼎科技  全球卓越'}}
                <br />
                {{lang === 't'  ?'人工智能  鼎盛未来' : '人工智能  鼎盛未来'}}
            </span>
          </div>
        </div>
      </div>
      <div class="group_5 flex-col">
        <span class="text_1">香港上鼎人工智能科技有限公司</span>
        <div class="block_2 flex-col"></div>
        <span class="text_2">500,000,000</span>
        <div class="text-wrapper_2">
          <span class="text_3">{{lang === 't'  ?'註冊資本' : '注册资本'}}</span>
          <span class="text_4">（{{lang === 't'  ?'港幣' : '港币  '}}）</span>
              <br />
              <span class="text_120">{{lang === 't'  ?'(人民币4.39億)' : '(人民币4.39亿)'}}</span>
        </div>
        <span class="text_5">500,000,000</span>
        <div class="text-wrapper_3">
          <span class="text_6">{{lang === 't'  ?'實繳資本' : '实缴资本'}}</span>
          <span class="text_7">（{{lang === 't'  ?'港幣' : '港币'}}）</span>
              <br />
              <span class="text_150">{{lang === 't'  ?'(人民币4.39億)' : '(人民币4.39亿)'}}</span>
        </div>
        <span class="text_8">1.37{{lang === 't'  ?'億' : '亿'}}</span>
        <div class="text-wrapper_4">
          <span class="text_9">{{lang === 't'  ?'現價值' : '现价值'}}</span>
          <span class="text_10">（{{lang === 't'  ?'美元' : '美元'}}）</span>
              <br />
              <span class="text_90">{{lang === 't'  ?'(人民币9.93億)' : '(人民币9.93亿)'}}</span>
        </div>
      </div>
      <div class="group_13 flex-col">
        <span class="text_11">{{lang === 't'  ?'公司簡介' : '公司简介'}}
        </span>
        <div class="group_7 flex-col"></div>
        <div class="image-wrapper_2 flex-row">
          <div class="image-text_2 flex-row justify-between">
            <img
              class="icon_2"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG1a941864131fc00de901660a7819829c.png"
            />
            <span class="text_12">上行鼎起&nbsp;智起未来</span>
          </div>
        </div>
        <span class="text_13">
          {{lang === 't'  ?'香港上鼎人工智能科技有限公司致力於發展EA及人工智能交易軟件，軟件經過多年驗證，在市場上取得了優越的回報。公司擁有超過30個專利著作權，其專利也被多家國際對沖基金授權使用。經過超過10年的大數據積累，其交易軟件能夠更精準地跟隨不同市場的短期及中期走勢，包括黃金、外匯及大宗商品等。依托一流的研發團隊基礎，公司近期成功開拓了虛擬資產的人工智能交易軟件。' : '香港上鼎人工智能科技有限公司致力于发展EA及人工智能交易软件，软件经过多年验证，在市场上取得了优越的回报。公司拥有超过30个专利著作权，其专利也被多家国际对冲基金授权使用。经过超过10年的大数据积累，其交易软件能够更精准地跟随不同市场的短期及中期走势，包括黄金、外汇及大宗商品等。依托一流的研发团队基础，公司近期成功开拓了虚拟资产的人工智能交易软件。'}}
        </span>
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG46a447ae1f5860b0653ee8c8ba5012cb.png"
        />
        <span class="text_14">{{lang === 't'  ?'我們是一家引領人工智能創新的公司，致力於利用先進技術解決復雜的現實世界問題。' : '我们是一家引领人工智能创新的公司，致力于利用先进技术解决复杂的现实世界问题。'}}</span>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG4f99a4a86a5617c4e76315fd28a8e814.png"
        />
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG57d305717bee5e09e903b0f70f632b15.png"
        />
      </div>
      <div class="group_9 flex-col">
        <span class="text_15">愿景引领</span>
        <div class="box_3 flex-col"></div>
        <div class="list_2 flex-col">
          <div
            class="text-wrapper_5 flex-col"
            :style="{ background: item.lanhuBg0 }"
            v-for="(item, index) in loopData0"
            :key="index"
          >
            <span class="text_16" :style="{ color: item.lanhufontColor0 }" v-html="item.lanhutext0"></span>
            <span class="text_17" :style="{ color: item.lanhufontColor1 }" v-html="item.lanhutext1"></span>
          </div>
        </div>
      </div>
      <div class="group_10 flex-col">
        <div class="box_6 flex-row">
          <!-- <span class="text_18">快速链接</span> -->
          <span class="text_19">公司地址</span>
          <!-- <div class="image-text_3 flex-row justify-between">
            <span class="text-group_1">简繁切换</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div> -->
        </div>
        <div class="box_7 flex-row">
          <!-- <span class="paragraph_2">
            关于我们
            <br />
            业务介绍
            <br />
            团队介绍
            <br />
            国际市场
          </span> -->
          <div class="group_14 flex-col justify-between">
            <span class="text_20">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
            <!-- <div class="text-group_3 flex-col justify-between">
              <span class="text_21">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
              <span class="text_22">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
            </div> -->
          </div>
          <!-- <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG49943e02bfbfa19e3901d302d72cfe2f.png"
          />
          <img
            class="icon_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG7a78523d690336a9fd29835e0a7e07d8.png"
          /> -->
        </div>
      </div>
      <div class="group_12 flex-col">
        <span class="text_23">{{lang === 't'  ?'創始股東' : '创始股东'}}</span>
        <div class="box_4 flex-col"></div>
        <span class="text_24">{{lang === 't'  ?'戰略股東' : '战略股东'}}</span>
        <div class="box_5 flex-col"></div>
        <div class="text-wrapper_6">
          <span class="paragraph_3">
            <br />
              {{lang === 't'  ?'史鵬勇' : '史鹏勇'}}
              <br />
              {{lang === 't'  ?'中國上鼎集團有限公司' : '中国上鼎集团有限公司'}}
              <br />
              {{lang === 't'  ?'中國華安資產有限公司' : '中国华安资产有限公司'}}
          </span>
          <span class="text_25"></span>
        </div>
        <div class="text-wrapper_7">
          <span class="paragraph_4">
            <br />
            {{lang === 't'  ?'深圳市一鼎實業投資集團有限公司' : '深圳市一鼎实业投资集团有限公司'}}
            <br />
            {{lang === 't'  ?'深圳市上鼎投資集團有限公司' : '深圳市上鼎投资集团有限公司'}}
            <br />
            {{lang === 't'  ?'深圳市上鼎高新科技有限公司' : '深圳市上鼎高新科技有限公司'}}
          </span>
          <span class="text_26"></span>
        </div>
      </div>
    </div>
    <div class="slider_bg" :class="show === null ? '' : show=== false ? 'hide': 'show'" @click="showSlider"></div>
    <div class="slider-list" :class="show === null ? '' : show=== false ? 'hide': 'show'">
      <div class="close" @click="showSlider"></div>
      <p class="txt" @click="$router.push({path:'/index_mobile'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
      <p class="txt" @click="$router.push({path:'/news_mobile'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
      <p class="txt" @click="$router.push({path:'/features_mobile'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/team_mobile'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/market_mobile'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
      <div class="lang" @click="changeLan">简体 ｜ 繁体</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        {
          lanhuBg0: 'transparent',
          lanhutext0:window.localStorage.getItem('lang') === 't'  ?'使命' : '使命',
          lanhufontColor0: 'rgba(13,86,151,1.000000)',
          lanhutext1: window.localStorage.getItem('lang') === 't'?'為全球客戶提供卓越的定製金融解決方案，助力企業實現卓越發展。' : '为全球客户提供卓越的定制金融解决方案，助力企业实现卓越发展。',
          lanhufontColor1: 'rgba(13,86,151,1.000000)'
        },
        {
          lanhuBg0: 'rgba(44,140,255,1.000000)',
          lanhutext0: window.localStorage.getItem('lang') === 't'  ?'理念' : '理念',
          lanhufontColor0: 'rgba(255,255,255,1.000000)',
          lanhutext1: window.localStorage.getItem('lang') === 't'?'成為全球領先的一站式新金融顧問服務提供商' : '成为全球领先的一站式新金融顾问服务提供商',
          lanhufontColor1: 'rgba(255,255,255,1.000000)'
        },
        {
          lanhuBg0: 'transparent',
          lanhutext0: window.localStorage.getItem('lang') === 't'  ?'願景' : '愿景',
          lanhufontColor0: 'rgba(13,86,151,1.000000)',
          lanhutext1: window.localStorage.getItem('lang') === 't' ? '團隊協作為基礎 卓越管理為核心 量身定製為特色 金融賦能為目標' : '团队协作为基础 卓越管理为核心 量身定制为特色 金融赋能为目标',
          lanhufontColor1: 'rgba(13,86,151,1.000000)'
        }
      ],
      lang: window.localStorage.getItem('lang') || 't',
      show: null,
      isBlackTop: false,
    };
  },
  methods: {
    showSlider() {
      this.show = !this.show
    },
    // changeLan(t) {
    //   window.localStorage.setItem('lang', t);
    //   window.location.reload();
    // },
       changeLan() {
        const lang = window.localStorage.getItem('lang')
        window.localStorage.setItem('lang', lang === 't' ? 's': 't');
        window.location.reload();
    },
     resetFontSize() {
        const size = (document.documentElement.clientWidth / 1080) * 37.5;
        document.documentElement.style.fontSize = size + 'px';
    },
    onScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 130) {
        this.isBlackTop = true
      } else {
        this.isBlackTop = false
      }
    },
  },
  created() {

    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.addEventListener('pageshow', this.resetFontSize);
    window.addEventListener('resize', this.resetFontSize);
    window.addEventListener('scroll', this.onScroll);
  } else {
    this.$router.push({path: '/index'})
  }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />