<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col">
        <div class="group_1 flex-col">
          <div class="group_16 flex-row">
            <img
              class="image_18"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
            <!-- <div class="group_17 flex-col justify-between">
              <div class="box_25 flex-col"></div>
              <div class="box_26 flex-col"></div>
            </div> -->
            <!-- <span class="text_30">关于我们</span>
            <span class="text_31">业务介绍</span>
            <span class="text_32">团队介绍</span>
            <span class="text_33">国际市场</span> -->
            <span class="text_30" @click="$router.push({path:'/index'})">{{lang === 't' ? '關於我們':'关于我们'}}</span>
        <span class="text_1011" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</span>
            <span class="text_31" @click="$router.push({path:'/features'})">{{lang === 't' ?'業務介紹': '业务介绍' }} </span>
            <span class="text_32" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</span>
            <span class="text_33" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</span>
            <span class="text_34" :class="{show: show}" @click="showLang">
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                <p class="trans" @click="changeLan('t')">繁体中文</p>
                <p  @click="changeLan('s')" >简体中文</p>
              </div>
            </span>
            <img
             @click="showLang"
              class="label_4"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div>
          
          <div class="text-wrapper_10 flex-row"><span class="text_6">{{lang === 't'  ?'全球·全局·洞悉·精準' : '全球·全局·洞悉·精准'}}</span></div>
          <div class="text-wrapper_11 flex-row">
            <span class="text_7">Global,&nbsp;Global,&nbsp;Insight,&nbsp;and&nbsp;Precision</span>
          </div>
          <div class="group_4 flex-col">
            <span class="text_8">{{lang === 't'  ?'EA交易系統-人工智能' : 'EA交易系统-人工智能'}}</span>
            <div class="block_2 flex-col"></div>
          </div>
        </div>
        <span class="text_9">{{lang === 't'  ?'一種交易系統，通過軟件系統在全球外匯市場上進行買賣，以實現絕對回報為目標' : '一种交易系统，通过软件系统在全球外汇市场上进行买卖，以实现绝对回报为目标'}}</span>
      </div>
      <div class="group_18 flex-col">
        <div class="section_8 flex-row justify-between">
          <div class="section_1 flex-col">
            <span class="text_10">{{lang === 't'  ?'重磅武器-EA多幣種量化交易系統' : '重磅武器-EA多币种量化交易系统'}}</span>
            <div class="box_7 flex-col"></div>
            




            <span class="paragraph_1">
              {{lang === 't'  ?'經過18年自主研發的智能交易平臺，獲得了國家十項軟件著作權。' : '经过18年自主研发的智能交易平台，获得了国家十项软件著作权。'}}
              
              <br />
              <br />
              {{lang === 't'  ?'通過大數據分析並結合EA智能技術、統計學和概率學，利用中長線貿易波段和多幣種量化對沖交易策略，有效規避風險，實現利潤的持續穩定增長，年化收益率超過100%。' : '通过大数据分析并结合EA智能技术、统计学和概率学，利用中长线贸易波段和多币种量化对冲交易策略，有效规避风险，实现利润的持续稳定增长，年化收益率超过100%。'}}

              
              <br />
              <br />
              {{lang === 't'  ?'嚴格的倉位管理以及多策略並行數據測試確保了交易的科學性和安全性。' : '严格的仓位管理以及多策略并行数据测试确保了交易的科学性和安全性。'}}
              
            </span>
          </div>
          <div class="image-wrapper_1 flex-col justify-center align-center">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG0f08fbc3c88a2f1d536a7d127546a2e9.png"
            />
          </div>
        </div>
      </div>
      <div class="group_19 flex-col">
        <span class="text_11">
          {{lang === 't'  ?'軟件著作權' : '软件著作权'}}</span>
        <div class="box_8 flex-col"></div>
        <span class="text_12">
          {{lang === 't'  ?'香港上鼎人工智能科技有限公司目前已申請通過的軟著有數十個，並累計近千個軟著在走流程審批中' : '香港上鼎人工智能科技有限公司目前已申请通过的软著有数十个，并累计近千个软著在走流程审批中'}}
          ......
        </span>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG5782d9b9f1246e98b493f238cecfc9a8.png"
        />
      </div>
      <div class="box_13 flex-row">
        <div class="block_5 flex-col">
          <span class="text_18">{{lang === 't' ?'AI 人工智能算法分析': 'AI 人工智能算法分析' }}</span>
          <span class="paragraph_4">
            {{lang === 't' ?'實時市場分析': '实时市场分析' }}
            <br />
            {{lang === 't' ?'機器學習優化': '机器学习优化' }}
          </span>
          <img
            class="image_13"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGeb2acc8a1af7958d47a7d3962f1f48cb.png"
          />
        </div>
        <div class="block_6 flex-col">
          <span class="text_19">{{lang === 't' ?'量化交易技術': '量化交易技术' }}</span>
          <span class="paragraph_5">
             
            {{lang === 't' ?'統計學和概率學模型': '统计学和概率学模型' }}
            
            <br />
            {{lang === 't' ?'自動化交易執行': '自动化交易执行' }}
            
          </span>
          <img
            class="image_14"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG378eeeff5e9196b5d032d45d1084fc95.png"
          />
        </div>
        <div class="block_7 flex-col">
          <div class="text-group_15 flex-col justify-between">
            
            <span class="text_20">
            {{lang === 't' ?'多幣種、多策略並行': '多币种、多策略并行' }}
            </span>
            <span class="paragraph_6">
            {{lang === 't' ?'多幣種交易': '多币种交易' }}
              <br />
            {{lang === 't' ?'多策略並行測試': '多策略并行测试' }}
            </span>
          </div>
          <img
            class="image_15"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGb4497deaefcca334a5d4b64093eec131.png"
          />
        </div>
        <div class="block_8 flex-col">
          <div class="text-group_16 flex-col justify-between">
            <span class="text_21">
            {{lang === 't' ?'嚴格的風險管理': '严格的风险管理' }}
            </span>
             
            <span class="paragraph_7">
            {{lang === 't' ?'智能倉位管理': '智能仓位管理' }}
            
              <br />
            {{lang === 't' ?'實時風險監控': '实时风险监控' }}
            
            </span>
          </div>
          <img
            class="image_16"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4a8e81b72e810eeedb43fecd5af353e3.png"
          />
        </div>
        <div class="block_9 flex-col">
          <div class="text-group_17 flex-col justify-between">
            <span class="text_22">
            {{lang === 't' ?'新科技應用': '新科技应用' }}
            </span>
            
            

            <span class="paragraph_8">
            {{lang === 't' ?'引入Web3 技術': '引入Web3 技术' }}
              
              <br />
            {{lang === 't' ?'新金融科技創新': '新金融科技创新' }}

              
            </span>
          </div>
          <img
            class="image_17"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG853113ef10d9b45c2fc728926d953c0b.png"
          />
        </div>
      </div>
      <div class="box_14 flex-col">
        
        <span class="text_23">
          {{lang === 't' ?'香港上鼎參與外匯對沖基金的特點': '香港上鼎参与外汇对冲基金的特点' }}
          </span>
        <div class="group_20 flex-row justify-between">
          <div class="block_10 flex-col">
            <div class="section_2 flex-col"></div>
            <span class="text_24">
          {{lang === 't' ?'全球視野': '全球视野' }}
          </span>
            <span class="paragraph_9">
              

          {{lang === 't' ?'在全球外匯市場操作，利用各類貨幣匯率波動獲利。': '在全球外汇市场操作，利用各类货币汇率波动获利。' }}
              <br />
          {{lang === 't' ?'靈活應對不同市場變化，及時調整策略。': '灵活应对不同市场变化，及时调整策略。' }}
          
            </span>
            <div class="section_3 flex-col"></div>
          </div>
          <div class="block_11 flex-col">
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc843abe338a9f35000fcf13392db751c.png"
            />
            <span class="text_25">
          {{lang === 't' ?'絕對回報目標': '绝对回报目标' }}
          </span>
          

            <span class="paragraph_10">
          {{lang === 't' ?'追求絕對回報，不受市場整體走勢限製。': '追求绝对回报，不受市场整体走势限制。' }}
              
              <br />
          {{lang === 't' ?'無論牛市熊市，均力求實現正收益。': '无论牛市熊市，均力求实现正收益。' }}

              
            </span>
            <div class="box_16 flex-col"></div>
          </div>
          <div class="block_12 flex-col">
            <div class="box_17 flex-col"></div>
            <span class="text_26">
          {{lang === 't' ?'嚴格的風險管理': '严格的风险管理' }}
          </span>
            <span class="paragraph_11">
          {{lang === 't' ?'采用多種風險管理策略，控製投資風險。': '采用多种风险管理策略，控制投资风险。' }}
          
          
              <br />
          {{lang === 't' ?'使用EA 多幣種量化交易系統進行嚴格倉位管理。': '使用EA 多币种量化交易系统进行严格仓位管理。' }}
              
            </span>
            <div class="box_18 flex-col"></div>
          </div>
        </div>
        <div class="group_21 flex-row justify-between">
          <div class="box_20 flex-col">
            <div class="group_7 flex-col"></div>
            <span class="text_27">
          {{lang === 't' ?'多幣種量化對沖交易': '多币种量化对冲交易' }}
           
          </span>
            <span class="paragraph_12">
          {{lang === 't' ?'多幣種交易分散風險。': '多币种交易分散风险。' }}
          
              <br />
          {{lang === 't' ?'采用量化對沖策略，精確控製每筆交易的風險和收益。': '采用量化对冲策略，精确控制每笔交易的风险和收益' }}
              
            </span>
            <div class="group_8 flex-col"></div>
          </div>
          <div class="box_21 flex-col">
            <div class="block_13 flex-col"></div>
            <span class="text_28">
          {{lang === 't' ?'AI 人工智能算法分析': 'AI 人工智能算法分析' }}

            </span>
            <span class="paragraph_13">
          {{lang === 't' ?'EA 系統運用AI 算法分析市場數據，提高交易決策準確性。實時監控市場動態，優化投資策略。': 'EA 系统运用AI 算法分析市场数据，提高交易决策准确性。实时监控市场动态，优化投资策略。' }}

              
              
            </span>
            <div class="block_14 flex-col"></div>
          </div>
          <div class="box_22 flex-col">
            <div class="box_23 flex-col"></div>
          
            
            <span class="text_29">{{lang === 't' ?'新科技和Web3 元素': '新科技和Web3 元素' }}</span>
            <span class="paragraph_14">
              
              {{lang === 't' ?'EA 系統與時俱進，提高交易效率和安全性。 ': 'EA 系统与时俱进，提高交易效率和安全性。' }}
              <br />
              {{lang === 't' ?'融入Web3 技術，數據透明和安全，提升信任。 ': '融入Web3&nbsp;技术，数据透明和安全，提升信任。' }}
              
            </span>
            <div class="box_24 flex-col"></div>
          </div>
        </div>
      </div>
      <div class="block_20 flex-row">
        <div class="text-wrapper_12 flex-col justify-between">
            <span class="text_36">{{lang === 't'  ?'快速鏈接' : '快速链接'}}</span>
          <span class="paragraph_15">
            <p class="txt" @click="$router.push({path:'/index'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
              <p class="txt" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
              <p class="txt" @click="$router.push({path:'/features'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
          </span>
        </div>
        <div class="box_29 flex-col">
          <span class="text_37">公司地址</span>
            <span class="text_38">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_18 flex-col justify-between">
              <span class="text_39">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
            <span class="text_40">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <img
          class="image_19"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGb36bb80c676d7b4c8778e245fd0d00e9.png"
        />
        <div class="box_30 flex-col justify-between">
          <img
            class="image_20"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG2185665b7a2678d6670dbf33e6d75422.png"
          />
          <div class="image-text_4 flex-row justify-between" @click="showLang">
            <span class="text-group_11" :class="{show: show}" >
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
                <div class="drag-down">
                  <p class="trans" @click="changeLan('t')">繁体中文</p>
                  <p  @click="changeLan('s')" >简体中文</p>
                </div>
            </span>
            <img
              class="label_5"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      lang: window.localStorage.getItem('lang') || 't',
    };
  },
  methods: {
    showLang() {
      this.show = !this.show
    },
    changeLan(t) {
      window.localStorage.setItem('lang', t);
      window.location.reload();
    },
  },
  created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.$router.push({path: '/features_mobile'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />