<template>
  <div class="page flex-col">
    <div class="section_1 flex-col"></div>
    <div class="section_2 flex-col">
      <div class="block_1 flex-row justify-between">
        <div class="block_2 flex-col">
          <span class="text_1">{{lang === 't' ?'AI 人工智能算法分析': 'AI 人工智能算法分析' }}</span>
          <span class="paragraph_1">
            {{lang === 't' ?'實時市場分析': '实时市场分析' }}
            <br />
            {{lang === 't' ?'機器學習優化': '机器学习优化' }}

          </span>
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG435b1409e9e5b5569881bd108d33836b.png"
          />
        </div>
        <div class="block_3 flex-col">
          <span class="text_2">{{lang === 't' ?'量化交易技術': '量化交易技术' }}</span>
          <span class="paragraph_2">
            {{lang === 't' ?'統計學和概率學模型': '统计学和概率学模型' }}

            <br />
            {{lang === 't' ?'自動化交易執行': '自动化交易执行' }}
          </span>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGd2eae547446d5cf801fdf0a5f369a86b.png"
          />
        </div>
        <div class="block_4 flex-col">
          <div class="text-group_1 flex-col justify-between">
            <span class="text_3">
            {{lang === 't' ?'多幣種、多策略並行': '多币种、多策略并行' }}
              
            </span>
            <span class="paragraph_3">
            {{lang === 't' ?'多幣種交易': '多币种交易' }}

              <br />
            {{lang === 't' ?'多策略並行測試': '多策略并行测试' }}

            </span>
          </div>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG10d1189801b47a4817c3255ae32a269e.png"
          />
        </div>
      </div>
      <div class="block_5 flex-row justify-between">
        <div class="block_6 flex-col">
          <div class="text-group_2 flex-col justify-between">
            <span class="text_4">            {{lang === 't' ?'嚴格的風險管理': '严格的风险管理' }}
            </span>
            <span class="paragraph_4">
            {{lang === 't' ?'智能倉位管理': '智能仓位管理' }}
              <br />
            {{lang === 't' ?'實時風險監控': '实时风险监控' }}
          </span>
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4a8e81b72e810eeedb43fecd5af353e3.png"
          />
        </div>
        <div class="block_7 flex-col">
          <div class="text-group_3 flex-col justify-between">
            <span class="text_5">
            {{lang === 't' ?'新科技應用': '新科技应用' }}
              
            </span>
            <span class="paragraph_5">
            {{lang === 't' ?'引入Web3 技術': '引入Web3 技术' }}
              <br />
            {{lang === 't' ?'新金融科技創新': '新金融科技创新' }}
          </span>
          </div>
          <img
            class="image_5"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG853113ef10d9b45c2fc728926d953c0b.png"
          />
        </div>
      </div>
    </div>
    <div class="section_3 flex-col">
      <span class="text_6">
        {{lang === 't' ?'香港上鼎參與外匯對沖基金的特點': '香港上鼎参与外汇对冲基金的特点' }}
        
      </span>
      <div class="block_8 flex-row justify-between">
        <div class="box_1 flex-col">
          <div class="box_2 flex-col"></div>
          <div class="text-group_4 flex-col justify-between">
            <span class="text_7">          {{lang === 't' ?'全球視野': '全球视野' }}
            </span>
            <span class="text_8">
              
          {{lang === 't' ?'在全球外匯市場操作，利用各類貨幣匯率波動獲利。': '在全球外汇市场操作，利用各类货币汇率波动获利。' }}
          {{lang === 't' ?'靈活應對不同市場變化，及時調整策略。': '灵活应对不同市场变化，及时调整策略。' }}
            </span>
          </div>
          <div class="icon_1 flex-col"></div>
        </div>
        <div class="box_3 flex-col">
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG7448cdf9aef968fa1882a28152b639b3.png"
          />
          <div class="text-group_5 flex-col justify-between">
            <span class="text_9">          {{lang === 't' ?'絕對回報目標': '绝对回报目标' }}
            </span>
            <span class="paragraph_6">
          {{lang === 't' ?'追求絕對回報，不受市場整體走勢限製。': '追求绝对回报，不受市场整体走势限制。' }}
              <br />
          {{lang === 't' ?'無論牛市熊市，均力求實現正收益。': '无论牛市熊市，均力求实现正收益。' }}
        </span>
          </div>
          <div class="icon_2 flex-col"></div>
        </div>
      </div>
      <div class="block_9 flex-row justify-between">
        <div class="group_1 flex-col">
          <div class="section_4 flex-col"></div>
          <div class="text-group_6 flex-col justify-between">
            <span class="text_10">
          {{lang === 't' ?'嚴格的風險管理': '严格的风险管理' }}
              
            </span>
            <span class="paragraph_7">
          {{lang === 't' ?'采用多種風險管理策略，控製投資風險。': '采用多种风险管理策略，控制投资风险。' }}
              <br />
          {{lang === 't' ?'使用EA 多幣種量化交易系統進行嚴格倉位管理。': '使用EA 多币种量化交易系统进行严格仓位管理。' }}
            </span>
          </div>
          <div class="icon_3 flex-col"></div>
        </div>
        <div class="group_2 flex-col">
          <div class="group_3 flex-col"></div>
          <div class="text-group_7 flex-col justify-between">
            <span class="text_11">
          {{lang === 't' ?'多幣種量化對沖交易': '多币种量化对冲交易' }}
              
            </span>
            <span class="paragraph_8">
          {{lang === 't' ?'多幣種交易分散風險。': '多币种交易分散风险。' }}
          <br />
          {{lang === 't' ?'采用量化對沖策略，精確控製每筆交易的風險和收益。': '采用量化对冲策略，精确控制每笔交易的风险和收益' }}
            </span>
          </div>
          <div class="icon_4 flex-col"></div>
        </div>
      </div>
      <div class="block_10 flex-row justify-between">
        <div class="group_4 flex-col">
          <div class="section_5 flex-col"></div>
          <div class="text-group_8 flex-col justify-between">
            <span class="text_12">          {{lang === 't' ?'AI 人工智能算法分析': 'AI 人工智能算法分析' }}
            </span>
            <span class="paragraph_9">
              {{lang === 't' ?'EA 系統運用AI 算法分析市場數據，提高交易決策準確性。實時監控市場動態，優化投資策略。': 'EA 系统运用AI 算法分析市场数据，提高交易决策准确性。实时监控市场动态，优化投资策略。' }}
            </span>
          </div>
          <div class="icon_5 flex-col"></div>
        </div>
        <div class="group_5 flex-col">
          <div class="group_6 flex-col"></div>
          <div class="text-group_9 flex-col justify-between">
            <span class="text_13">{{lang === 't' ?'新科技和Web3 元素': '新科技和Web3 元素' }}</span>
            <span class="paragraph_10">
              {{lang === 't' ?'EA 系統與時俱進，提高交易效率和安全性。 ': 'EA 系统与时俱进，提高交易效率和安全性。' }}
              {{lang === 't' ?'融入Web3 技術，數據透明和安全，提升信任。 ': '融入Web3&nbsp;技术，数据透明和安全，提升信任。' }}
            </span>
          </div>
          <div class="icon_6 flex-col"></div>
        </div>
      </div>
    </div>
    <div class="section_6 flex-col">
      <div class="section_7 flex-col">
        <span class="text_14">{{lang === 't'  ?'重磅武器-EA多幣種量化交易系統' : '重磅武器-EA多币种量化交易系统'}}</span>
        <div class="group_7 flex-col"></div>
        <span class="paragraph_11">
          {{lang === 't'  ?'經過18年自主研發的智能交易平臺，獲得了國家十項軟件著作權。' : '经过18年自主研发的智能交易平台，获得了国家十项软件著作权。'}}

          <br />
          <br />
          {{lang === 't'  ?'通過大數據分析並結合EA智能技術、統計學和概率學，利用中長線貿易波段和多幣種量化對沖交易策略，有效規避風險，實現利潤的持續穩定增長，年化收益率超過100%。' : '通过大数据分析并结合EA智能技术、统计学和概率学，利用中长线贸易波段和多币种量化对冲交易策略，有效规避风险，实现利润的持续稳定增长，年化收益率超过100%。'}}
          <br />
          <br />
          {{lang === 't'  ?'嚴格的倉位管理以及多策略並行數據測試確' : '严格的仓位管理以及多策略并行数据测试'}}
          <br />
          {{lang === 't'  ?'確保了交易的科學性和安全性。' : '确保了交易的科学性和安全性。'}}
          <br />
        </span>
      </div>
      <div class="image-wrapper_1 flex-col justify-center align-center">
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG3da048f025cc2a792ef3fe63bd1c8363.png"
        />
      </div>
    </div>
    <div class="section_8 flex-col">
      <span class="text_15">{{lang === 't'  ?'軟件著作權' : '软件著作权'}}</span>
      <div class="box_4 flex-col"></div>
      <span class="text_16">
        {{lang === 't'  ?'香港上鼎人工智能科技有限公司目前已申請通過的軟著有數十個，並累計近千個軟著在走流程審批中' : '香港上鼎人工智能科技有限公司目前已申请通过的软著有数十个，并累计近千个软著在走流程审批中'}}......
      </span>
      <img
        class="image_7"
        referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNGe6dfb90f4d83172d523021291ff9b742.png"
      />
    </div>
    <div class="section_9 flex-col">
      <div class="nav-bar_1 flex-row" :class="{black: isBlackTop}">
        <div class="group_8 flex-col">
          <div class="image-wrapper_2 flex-row justify-between">
            <img
              class="icon_7"                @click="showSlider"

              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG0d956aa7ed8e338a0078ca75fb8e3c13.png"
            />
            <img
              class="image_8"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
          </div>
        </div>
      </div>
      <span class="text_17">{{lang === 't'  ?'全球·全局·洞悉·精準' : '全球·全局·洞悉·精准'}}</span>
      <span class="text_18">Global,&nbsp;Global,&nbsp;Insight,&nbsp;and&nbsp;Precision</span>
      <div class="block_11 flex-col">
        <span class="text_19">{{lang === 't'  ?'EA交易系統-人工智能' : 'EA交易系统-人工智能'}}</span>
        <div class="section_10 flex-col"></div>
        <span class="text_20">{{lang === 't'  ?'一種交易系統，通過軟件系統在全球外匯市場上進行買賣，以實現絕對回報為目標' : '一种交易系统，通过软件系统在全球外汇市场上进行买卖，以实现绝对回报为目标'}}</span>
      </div>
    </div>
    <div class="section_11 flex-col">
      <div class="block_12 flex-row">
        <!-- <span class="text_21">快速链接</span> -->
        <span class="text_22">公司地址</span>
        <!-- <div class="image-text_1 flex-row justify-between">
          <span class="text-group_10">简繁切换</span>
          <img
            class="label_2"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
          />
        </div> -->
      </div>
      <div class="block_13 flex-row">
        <!-- <span class="paragraph_12">
          关于我们
          <br />
          业务介绍
          <br />
          团队介绍
          <br />
          国际市场
        </span> -->
        <div class="block_14 flex-col justify-between">
          <span class="text_23">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_11 flex-col justify-between">
            <span class="text_24">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
            <span class="text_25">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <!-- <img
          class="image_9"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG49943e02bfbfa19e3901d302d72cfe2f.png"
        />
        <img
          class="icon_8"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG7a78523d690336a9fd29835e0a7e07d8.png"
        /> -->
      </div>
    </div>
    <div class="slider_bg" :class="show === null ? '' : show=== false ? 'hide': 'show'" @click="showSlider"></div>
    <div class="slider-list" :class="show === null ? '' : show=== false ? 'hide': 'show'">
      <div class="close" @click="showSlider"></div>
      <p class="txt" @click="$router.push({path:'/index_mobile'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
      <p class="txt" @click="$router.push({path:'/news_mobile'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
      <p class="txt" @click="$router.push({path:'/features_mobile'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/team_mobile'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/market_mobile'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
      <div class="lang" @click="changeLan">简体 ｜ 繁体</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: null,
      isBlackTop: false,
    };
  },
  methods: {
    showSlider() {
      this.show = !this.show
    },
    // changeLan(t) {
    //   window.localStorage.setItem('lang', t);
    //   window.location.reload();
    // },
       changeLan() {
        const lang = window.localStorage.getItem('lang')
        window.localStorage.setItem('lang', lang === 't' ? 's': 't');
        window.location.reload();
    },
     resetFontSize() {
        const size = (document.documentElement.clientWidth / 1080) * 37.5;
        document.documentElement.style.fontSize = size + 'px';
    },
    onScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 130) {
        this.isBlackTop = true
      } else {
        this.isBlackTop = false
      }
    },
  },
  created() {
  // reset root font size on page show or resize
  
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.addEventListener('pageshow', this.resetFontSize);
    window.addEventListener('resize', this.resetFontSize);
    window.addEventListener('scroll', this.onScroll);
  } else {
    this.$router.push({path: '/features'})
  }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />