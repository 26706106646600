<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="box_2 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/logo.png"
        />
        <!-- <div class="group_1 flex-col justify-between">
          <div class="box_3 flex-col"></div>
          <div class="box_4 flex-col"></div>
        </div> -->
        <!-- <span class="text_1">关于我们</span>
        <span class="text_2">新闻资讯</span>
        <span class="text_3">业务介绍</span>
        <span class="text_4">团队介绍</span>
        <span class="text_5">国际市场</span> -->

        <span class="text_1" @click="$router.push({path:'/index'})">{{lang === 't' ? '關於我們':'关于我们'}}</span>
        <span class="text_2" @click="$router.push({path:'/market'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</span>
        <span class="text_3" @click="$router.push({path:'/features'})">{{lang === 't' ?'業務介紹': '业务介绍' }} </span>
        <span class="text_4" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</span>
        <span class="text_5" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</span>
        <span class="text_6" :class="{show: show}" @click="showLang">
            {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                <p class="trans" @click="changeLan('t')">繁体中文</p>
                <p  @click="changeLan('s')" >简体中文</p>
              </div>
          </span>
        <img
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG27fa0ae6011f640bee5d0547a1972f8f.png"
        />
      </div>
      <div class="box_5 flex-col">
        <span class="text_7">{{lang === 't' ? '新聞資訊':'新闻资讯'}}&gt;正文</span>
        <div class="box_6 flex-col">
          <span class="text_8">{{lang === 't' ? '上鼎國際與美國上市公司簽訂合作協議':'上鼎国际与美国上市公司签订合作协议'}}</span>
          <div class="box_10 flex-row">
            <span class="text_9">{{lang === 't' ? '發布時間':'发布时间'}}&nbsp;2024-08-06&nbsp;11:00</span>
            <!-- <span class="text_10">浏览次数&nbsp;999</span>
            <span class="text_11">发布者&nbsp;&nbsp;张三</span> -->
            <div class="image-text_3 flex-row justify-between" @click="back">
              <span class="text-group_1">返回上一页</span>
              <img
                class="label_2"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNG2c09a382efcc7ec625ecdd3a5cbb9f7d.png"
              />
            </div>
          </div>
          <span class="paragraph_1">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{lang === 't' ? '經過公司管理層與美國上市公司的多次深入溝通，雙方已正式簽署合作意向書，標誌着合作關係的正式確立。同時，我們已順利通過盡職調查，確保合作基礎穩固。目前，雙方團隊正緊密配合，合同內容的每個細節都經過精心雕琢，現已全部擬定完畢，將在8月15日簽訂合作協議。這一里程碑式的合作，不僅彰顯了我們公司國際化戰略的堅定決心，更為我們共同開創更加輝煌的業績奠定了堅實的基礎。':'经过公司管理层与美国上市公司的多次深入沟通，双方已正式签署合作意向书，标志着合作关系的正式确立。同时，我们已顺利通过尽职调查，确保合作基础稳固。目前，双方团队正紧密配合，合同内容的每个细节都经过精心雕琢，现已全部拟定完毕，将在8月15日签订合作协议。这一里程碑式的合作，不仅彰显了我们公司国际化战略的坚定决心，更为我们共同开创更加辉煌的业绩奠定了坚实的基础。'}}
          </span>
          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG5c7c0e0c3f5a1c65bc79b16314832bb2.png"
          />
          <!-- <span class="paragraph_2">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国的经济经历30多年改革开放的发展，金融市场从效事较为低下的初级阶段，向高效而活跃的新时代过渡这使得金融模式的创新成为发展的必然趋势。
            <br />
            2003年，泡沫破碎后的互联网行业重新爆发出新的活力，呈现强劲的增长态势。着金融与互联网交叉渗透的深入，互联网的经济模式已经孕育出很多具有竞争力的创新金融模式。
          </span> -->
          <div class="text-wrapper_3 flex-row justify-between">
            <span class="text_12">上一篇：{{lang==='t'? '暫無': '暂无'}}</span>
            <span class="text_13">下一篇：{{lang==='t'? '暫無': '暂无'}}</span>
          </div>
        </div>
      </div>
      <div class="box_7 flex-row">
        <div class="text-wrapper_4 flex-col justify-between">
          <span class="text_14">{{lang === 't'  ?'快速鏈接' : '快速链接'}}</span>
          <span class="paragraph_3">
            <p class="txt" @click="$router.push({path:'/index'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
              <p class="txt" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
              <p class="txt" @click="$router.push({path:'/features'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
          </span>
        </div>
        <div class="group_2 flex-col">
          <span class="text_15">公司地址</span>
          <span class="text_16">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_4 flex-col justify-between">
            <span class="text_17">联系方式</span>
            <span class="text_18">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGb36bb80c676d7b4c8778e245fd0d00e9.png"
        />
        <div class="group_3 flex-col justify-between">
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG0bf20e3c4d5cc6ba25d8d4bbdc88f418.png"
          />
          <div class="image-text_4 flex-row justify-between" @click="showLang">
            <span class="text-group_3" :class="{show: show}">
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                  <p class="trans" @click="changeLan('t')">繁体中文</p>
                  <p  @click="changeLan('s')" >简体中文</p>
                </div>
            </span>
            
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const num = 1; // 新闻数  此页面搜 group_8
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: false,
      height: (54 + num*3.52) + 'rem',
    };
  },
  methods: {
    back() {
      history.back();
    },
    showLang() {
      this.show = !this.show
    },
    changeLan(t) {
      window.localStorage.setItem('lang', t);
      window.location.reload();
    },
  },
  created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.$router.push({path: '/news_mobile_detail'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />