import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'
import market from '../views/market/index.vue'
import features from '../views/features/index.vue'
import team from '../views/team/index.vue'
import news from '../views/news/index.vue'
import pdf from '../views/pdf/index.vue'
import indexMobile from '../views/index_mobile/index.vue'
import marketMobile from '../views/market_mobile/index.vue'
import featuresMobile from '../views/features_mobile/index.vue'
import teamMobile from '../views/team_mobile/index.vue'
import newsMobile from '../views/news_mobile/index.vue'
import newsDetail from '../views/news_detail/index.vue'
import newsDetail2 from '../views/news_detail2/index.vue'
import newsMobileDetail from '../views/news_mobile_detail/index.vue'
import newsMobileDetail2 from '../views/news_mobile_detail2/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/market',
    name: 'market',
    component: market
  },
  {
    path: '/features',
    name: 'features',
    component: features
  },
  {
    path: '/team',
    name: 'team',
    component: team
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/pdf',
    name: 'pdf',
    component: pdf
  },
  {
    path: '/index_mobile',
    name: 'index_mobile',
    component: indexMobile
  },
  {
    path: '/features_mobile',
    name: 'features_mobile',
    component: featuresMobile
  },
  {
    path: '/market_mobile',
    name: 'market_mobile',
    component: marketMobile
  },
  {
    path: '/team_mobile',
    name: 'team_mobile',
    component: teamMobile
  },
  {
    path: '/news_mobile',
    name: 'news_mobile',
    component: newsMobile
  },
  {
    path: '/news_detail',
    name: 'news_detail',
    component: newsDetail
  },
  {
    path: '/news_detail2',
    name: 'news_detail2',
    component: newsDetail2
  },
  {
    path: '/news_mobile_detail',
    name: 'news_mobile_detail',
    component: newsMobileDetail
  },
  {
    path: '/news_mobile_detail2',
    name: 'news_mobile_detail2',
    component: newsMobileDetail2
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
