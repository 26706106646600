<template>
  <div class="page flex-col">
    <div class="group_1 flex-col">
      <div class="block_3 flex-row">
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/logo.png"
        />
        <!-- <div class="block_4 flex-col justify-between">
          <div class="box_1 flex-col"></div>
          <div class="box_2 flex-col"></div>
        </div> -->
        <span class="text_1" @click="$router.push({path:'/index'})">{{lang === 't' ? '關於我們':'关于我们'}}</span>
        <span class="text_1011" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</span>
            <span class="text_2" @click="$router.push({path:'/features'})">{{lang === 't' ?'業務介紹': '业务介绍' }} </span>
            <span class="text_3" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</span>
            <span class="text_4" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</span>
            <span class="text_5" :class="{show: show}" @click="showLang">
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                <p class="trans" @click="changeLan('t')">繁体中文</p>
                <p  @click="changeLan('s')" >简体中文</p>
              </div>
            </span>
        <img
             @click="showLang"
          class="label_1"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
        />
      </div>
      <div class="text-wrapper_5 flex-row">
        <span class="paragraph_1">
          {{lang === 't'  ?'一站式新金融' : '一站式新金融'}}
          <br />
          {{lang === 't'  ?'服務提供商' : '服务提供商'}}
        </span>
      </div>
      <div class="block_5 flex-row justify-between">
        <img
          class="image_2"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGd4a93900a2816a3ad4f255b03fa96c94.png"
        />
        <div class="text-wrapper_2 flex-col"><span class="text_6">
          
          
          {{lang === 't'  ?'全球領先' : '全球领先'}}
          </span></div>
      </div>
    </div>
    <div class="box_10 flex-col">
      <div class="text-wrapper_6 flex-row"><span class="text_7">上鼎全球&nbsp;金融卓越</span></div>
      <div class="block_6 flex-row"><div class="box_4 flex-col"></div></div>
      <div class="block_7 flex-row justify-between">
        <div class="image-wrapper_2 flex-col justify-between">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG18de3bfd8f86c516367c9e833dc0021c.png"
          />
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG2cca8caf60a369c6a351be4dc8d93483.png"
          />
        </div>
        <div class="group_7 flex-col justify-between">
          <div class="group_4 flex-row">
            <div class="text-group_4 flex-col justify-between">
              <span class="text_8">
                {{lang === 't'  ?'登陸國際市場' : '登陆国际市场'}}
                </span>
              <span class="text_9">
                {{lang === 't'  ?'為全球客戶提供卓越的定製金融解決方案，助力企業實現卓越發展。' : '为全球客户提供卓越的定制金融解决方案，助力企业实现卓越发展。'}}
                </span>
            </div>
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG31874deaed2e49d731f11f2508b88c93.png"
            />
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG1cce184e6f4f22fb0f3bc6c4572960d1.png"
            />
          </div>
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4ad3aa1b4c965d80a61b3a63df5dcaa4.png"
          />
        </div>
      </div>
      <div class="box_6 flex-row">
        <div class="group_5 flex-col">
          <div class="section_2 flex-col">
            <div class="box_7 flex-col" @click="goUp">
               
              <span class="paragraph_2">
                {{lang === 't'  ?'查看各地' : '查看各地'}}
                
                <br />
                {{lang === 't'  ?'上市規則' : '上市规则'}}
                
              </span>
              <div class="group_6 flex-col justify-center"><div class="box_8 flex-col"></div></div>
            </div>
          </div>
        </div>
        <img
          class="image_8"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG9762059d54fa9119b61903e3b900f839.png"
        />
      </div>
      <div class="block_8 flex-row">
        <div class="text-wrapper_7 flex-col justify-between">
            <span class="text_14">{{lang === 't'  ?'快速鏈接' : '快速链接'}}</span>
          <span class="paragraph_4">
            <p class="txt" @click="$router.push({path:'/index'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
              <p class="txt" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
              <p class="txt" @click="$router.push({path:'/features'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
          </span>
        </div>
        <div class="box_11 flex-col">
          <span class="text_15">公司地址</span>
          <span class="text_16">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_5 flex-col justify-between">
    <span class="text_17">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>

            <span class="text_18">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <img
          class="image_11"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNGb36bb80c676d7b4c8778e245fd0d00e9.png"
        />
        <div class="box_12 flex-col justify-between">
          <img
            class="image_12"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG2185665b7a2678d6670dbf33e6d75422.png"
          />
          <div class="image-text_2 flex-row justify-between" @click="showLang">
            <span class="text-group_6" :class="{show: show}">
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
                <div class="drag-down">
                  <p class="trans" @click="changeLan('t')">繁体中文</p>
                  <p  @click="changeLan('s')" >简体中文</p>
                </div>
            </span>
            <img
              class="label_3"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: false,
    };
  },
  methods: {
    goUp() {
      window.open('/#/pdf', '_blank');
      // this.$router.push({path: '/pdf'})
    },
    showLang() {
      this.show = !this.show
    },
    changeLan(t) {
      window.localStorage.setItem('lang', t);
      window.location.reload();
    },
  },
  created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.$router.push({path: '/market_mobile'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />