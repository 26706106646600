<template>
    <div id="pdf"></div>
</template>

<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  mounted() {
    console.log('123123:', 123123)
    if(window.PDFObject.supportsPDFs){
        // PDF嵌入到网页
        window.PDFObject.embed("/up.pdf", "#pdf" );
    }
  }
};

</script>

<style>
#pdf {
  height: 100vh;
  width: 100%;
}
</style>