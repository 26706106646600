<template>
  <div class="page flex-col">
    <div class="block_1 flex-col">
      <div class="nav-bar_1 flex-row" :class="{black: isBlackTop}">
        <div class="block_2 flex-col">
          <div class="image-wrapper_1 flex-row justify-between">
            <img
              class="icon_1"
              @click="showSlider"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG0d956aa7ed8e338a0078ca75fb8e3c13.png"
            />
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
          </div>
        </div>
        <div class="block_3 flex-col justify-between">
          <div class="group_1 flex-col"></div>
          <div class="group_2 flex-col"></div>
        </div>
      </div>
      <span class="paragraph_1">
          {{lang === 't'  ?'一站式新金融' : '一站式新金融'}}
        <br />
          {{lang === 't'  ?'服務提供商' : '服务提供商'}}
      </span>
      <div class="text-wrapper_1 flex-col"><span class="text_1">
        {{lang === 't'  ?'全球領先' : '全球领先'}}
        
      </span></div>
      <img
        class="image_2"
        referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNGd4a93900a2816a3ad4f255b03fa96c94.png"
      />
    </div>
    <div class="block_4 flex-col">
      <span class="text_2">上鼎全球&nbsp;金融卓越</span>
      <div class="block_5 flex-col"></div>
      <div class="block_6 flex-row justify-between">
        <div class="image-wrapper_2 flex-col justify-between">
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG1b4e27a6d57fe58497fb18e0fcbfb93f.png"
          />
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG652d0d36e8fd667e235b585e7a6582a8.png"
          />
        </div>
        <div class="box_1 flex-col justify-between">
          <div class="section_1 flex-row">
            <div class="text-group_1 flex-col justify-between">
              <span class="text_3">                {{lang === 't'  ?'登陸國際市場' : '登陆国际市场'}}
              </span>
              <span class="text_4">
                {{lang === 't'  ?'為全球客戶提供卓越的定製金融解決方案，助力企業實現卓越發展。' : '为全球客户提供卓越的定制金融解决方案，助力企业实现卓越发展。'}}
                
              </span>
            </div>
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG70789236cd93b67b04138ab5e9a789d3.png"
            />
            <img
              class="image_6"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc42751f997d87b4967ed0bc1bd31e3d4.png"
            />
          </div>
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGa3c35f6bf2372bf689a31d9187a0fa3b.png"
          />
        </div>
      </div>
    </div>
    <div class="block_7 flex-row">
      <div class="group_3 flex-col">
        <div class="box_2 flex-col">
          <span class="paragraph_2" @click="goUp">
            {{lang === 't'  ?'查看各地' : '查看各地'}}
            <br />
            {{lang === 't'  ?'上市規則' : '上市规则'}}
          </span>
          <div class="group_4 flex-col"><div class="box_3 flex-col"></div></div>
        </div>
      </div>
      <img
        class="image_8"
        referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNG0087f8106caf82990dcd48fac4d507b9.png"
      />
      <img
        class="image_9"
        referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNGcbcd9a40f27d9122c43df2258f6677cf.png"
      />
    </div>
    <div class="block_8 flex-col">
      <div class="section_2 flex-row">
        <!-- <span class="text_5">快速链接</span> -->
        <span class="text_6">公司地址</span>
        <!-- <div class="image-text_1 flex-row justify-between">
          <span class="text-group_2">简繁切换</span>
          <img
            class="label_1"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
          />
        </div> -->
      </div>
      <div class="section_3 flex-row">
        <!-- <span class="paragraph_3">
          关于我们
          <br />
          业务介绍
          <br />
          团队介绍
          <br />
          国际市场
        </span> -->
        <div class="box_4 flex-col justify-between">
          <span class="text_7">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
          <!-- <div class="text-group_3 flex-col justify-between">
            <span class="text_8">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
            <span class="text_9">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
          </div> -->
        </div>
        <!-- <img
          class="image_10"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG49943e02bfbfa19e3901d302d72cfe2f.png"
        />
        <img
          class="image_11"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG7a78523d690336a9fd29835e0a7e07d8.png"
        /> -->
      </div>
    </div>
    <div class="slider_bg" :class="show === null ? '' : show=== false ? 'hide': 'show'" @click="showSlider"></div>
    <div class="slider-list" :class="show === null ? '' : show=== false ? 'hide': 'show'">
      <div class="close" @click="showSlider"></div>
      <p class="txt" @click="$router.push({path:'/index_mobile'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
      <p class="txt" @click="$router.push({path:'/news_mobile'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
      <p class="txt" @click="$router.push({path:'/features_mobile'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/team_mobile'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/market_mobile'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
      <div class="lang" @click="changeLan">简体 ｜ 繁体</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: null,
      isBlackTop: false,
    };
  },
  methods: {
    showSlider() {
      this.show = !this.show
    },
    goUp() {
      if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.open('/up.pdf', '_blank');
    } else {
      window.open('/#/pdf', '_blank');

    }
      // this.$router.push({path: '/pdf'})
    },
    // changeLan(t) {
    //   window.localStorage.setItem('lang', t);
    //   window.location.reload();
    // },
       changeLan() {
        const lang = window.localStorage.getItem('lang')
        window.localStorage.setItem('lang', lang === 't' ? 's': 't');
        window.location.reload();
    },
     resetFontSize() {
        const size = (document.documentElement.clientWidth / 1080) * 37.5;
        document.documentElement.style.fontSize = size + 'px';
    },
    onScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 50) {
        this.isBlackTop = true
      } else {
        this.isBlackTop = false
      }
    },
  },
  created() {
  // reset root font size on page show or resize
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    window.addEventListener('pageshow', this.resetFontSize);
    window.addEventListener('resize', this.resetFontSize);
    window.addEventListener('scroll', this.onScroll);
  } else {
    this.$router.push({path: '/market'})
  }
   
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />