<template>
  <div class="page flex-col">
    <div class="box_1 flex-col">
      <div class="block_1 flex-col">
        <div class="section_1 flex-col">
          <div class="group_28 flex-row">
            <img
              class="image_1"
              referrerpolicy="no-referrer"
              src="./assets/img/logo.png"
            />
            <!-- <div class="section_5 flex-col justify-between">
              <div class="group_2 flex-col"></div>
              <div class="group_3 flex-col"></div>
            </div> -->
            <span class="text_1" @click="$router.push({path:'/index'})">{{lang === 't' ? '關於我們':'关于我们'}}</span>
        <span class="text_1011" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</span>
            <span class="text_2" @click="$router.push({path:'/features'})">{{lang === 't' ?'業務介紹': '业务介绍' }} </span>
            <span class="text_3" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</span>
            <span class="text_4" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</span>
            <span class="text_5" :class="{show: show}" @click="showLang">
              {{lang === 't' ? '繁体中文' :  '简体中文'}}
              <div class="drag-down">
                <p class="trans" @click="changeLan('t')">繁体中文</p>
                <p  @click="changeLan('s')" >简体中文</p>
              </div>
            </span>
            <img
             @click="showLang"
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
            />
          </div>
          <div class="group_29 flex-row">
            <div class="text-wrapper_1 flex-col">
              <span class="paragraph_1">
                {{lang === 't'  ?'上鼎科技  全球卓越' : '上鼎科技  全球卓越'}}
                <br />
                {{lang === 't'  ?'人工智能  鼎盛未来' : '人工智能  鼎盛未来'}}
              </span>
            </div>
          </div>
          <div class="image-wrapper_3 flex-row">
            <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG8126130df4bcbdc33b117afe079fbb67.png"
            />
          </div>
        </div>
      </div>
      <div class="group_30 flex-col">
        <div class="group_6 flex-col">
          <div class="text-wrapper_15 flex-row"><span class="text_6">香港上鼎人工智能科技有限公司</span></div>
          <div class="box_13 flex-row"><div class="box_3 flex-col"></div></div>
          <div class="box_14 flex-row">
            <div class="block_5 flex-col justify-between">
              <span class="text_7">500,000,000</span>
              <div class="text-wrapper_3">
                <span class="text_8">{{lang === 't'  ?'註冊資本' : '注册资本'}}</span>
                <span class="text_9">（{{lang === 't'  ?'港幣' : '港币  '}}）</span>
              <br />
              <span class="text_90">{{lang === 't'  ?'(人民币4.39億)' : '(人民币4.39亿)'}}</span>
              </div>
            </div>
            <div class="block_6 flex-col justify-between">
              <span class="text_10">500,000,000</span>
              <div class="text-wrapper_4">
                <span class="text_11">{{lang === 't'  ?'實繳資本' : '实缴资本'}}</span>
                <span class="text_12">（{{lang === 't'  ?'港幣' : '港币'}}）</span>
                <!-- <span class="text_12">(港币)</span> -->
              <br />
              <span class="text_120">{{lang === 't'  ?'(人民币4.39億)' : '(人民币4.39亿)'}}</span>
              </div>
            </div>
            <div class="block_7 flex-col justify-between">
              <span class="text_13">1.37{{lang === 't'  ?'億' : '亿'}}</span>
              <div class="text-wrapper_5">
                <span class="text_14">{{lang === 't'  ?'現價值' : '现价值'}}</span>
                <span class="text_15">（{{lang === 't'  ?'美元' : '美元'}}）</span>
              <br />
                <span class="text_150">{{lang === 't'  ?'(人民币9.93億)' : '(人民币9.93亿)'}}</span>
              </div>
            </div>
          </div>
        </div>
        <span class="text_16">{{lang === 't'  ?'公司簡介' : '公司简介'}}</span>
        <div class="group_9 flex-col"></div>
        <div class="group_10 flex-row">
          <div class="image-text_5 flex-row justify-between">
            <img
              class="label_2"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNGca3785e9f09f7ed7f4d78ab4373654b1.png"
            />
            <span class="text-group_1">上行鼎起&nbsp;智起未来</span>
          </div>
        </div>
        <div class="group_31 flex-row justify-between">
          <div class="text-wrapper_16 flex-col justify-between">
            <span class="text_17">
              {{lang === 't'  ?'香港上鼎人工智能科技有限公司致力於發展EA及人工智能交易軟件，軟件經過多年驗證，在市場上取得了優越的回報。公司擁有超過30個專利著作權，其專利也被多家國際對沖基金授權使用。經過超過10年的大數據積累，其交易軟件能夠更精準地跟隨不同市場的短期及中期走勢，包括黃金、外匯及大宗商品等。依托一流的研發團隊基礎，公司近期成功開拓了虛擬資產的人工智能交易軟件。' : '香港上鼎人工智能科技有限公司致力于发展EA及人工智能交易软件，软件经过多年验证，在市场上取得了优越的回报。公司拥有超过30个专利著作权，其专利也被多家国际对冲基金授权使用。经过超过10年的大数据积累，其交易软件能够更精准地跟随不同市场的短期及中期走势，包括黄金、外汇及大宗商品等。依托一流的研发团队基础，公司近期成功开拓了虚拟资产的人工智能交易软件。'}}
             
            </span>
            <span class="text_18">{{lang === 't'  ?'我們是一家引領人工智能創新的公司，致力於利用先進技術解決復雜的現實世界問題。' : '我们是一家引领人工智能创新的公司，致力于利用先进技术解决复杂的现实世界问题。'}}</span>
            
          </div>
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGa2b048210309f647ee09de96cde4d80a.png"
          />
        </div>
        <div class="group_12 flex-col">
          <span class="text_19">{{lang === 't'  ?'創始股東' : '创始股东'}}</span>
          <div class="group_13 flex-col"></div>
          <span class="text_20">{{lang === 't'  ?'戰略股東' : '战略股东'}}</span>
          <div class="group_14 flex-col"></div>
          <div class="text-wrapper_7">
            <span class="paragraph_2">
              <br />
              1.&nbsp;{{lang === 't'  ?'史鵬勇' : '史鹏勇'}}
              <br />
              2.&nbsp;{{lang === 't'  ?'中國上鼎集團有限公司' : '中国上鼎集团有限公司'}}
              <br />
              3.&nbsp;{{lang === 't'  ?'中國華安資產有限公司' : '中国华安资产有限公司'}}
            </span>
            <span class="text_21"></span>
          </div>
          <div class="text-wrapper_8">
            <span class="paragraph_3">
              <br />
              1.&nbsp;{{lang === 't'  ?'深圳市一鼎實業投資集團有限公司' : '深圳市一鼎实业投资集团有限公司'}}
              <br />
              2.&nbsp;{{lang === 't'  ?'深圳市上鼎投資集團有限公司' : '深圳市上鼎投资集团有限公司'}}
              <br />
              3.&nbsp;{{lang === 't'  ?'深圳市上鼎高新科技有限公司' : '深圳市上鼎高新科技有限公司'}}
            </span>
            <span class="text_22"></span>
          </div>
        </div>
        <div class="group_15 flex-col">
          <div class="text-wrapper_17 flex-row"><span class="text_23">{{lang === 't'  ?'願景引領' : '愿景引领'}} </span></div>
          <div class="section_6 flex-row"><div class="group_17 flex-col"></div></div>
          <div class="section_7 flex-row">
            <div class="box_7 flex-row">
              <div class="text-group_11 flex-col justify-between">
                <span class="text_24">{{lang === 't'  ?'使命' : '使命'}} </span>
                <span class="text_25">{{lang === 't'  ?'為全球客戶提供卓越的定製金融解決方案，助力企業實現卓越發展。' : '为全球客户提供卓越的定制金融解决方案，助力企业实现卓越发展。'}}</span>
              </div>
            </div>
            <div class="box_8 flex-row">
              <div class="text-group_12 flex-col justify-between">
                <span class="text_26">{{lang === 't'  ?'理念' : '理念'}}</span>
                <span class="text_27">{{lang === 't'  ?'成為全球領先的一站式新金融顧問服務提供商' : '成为全球领先的一站式新金融顾问服务提供商'}}</span>
              </div>
            </div>
            <div class="box_9 flex-row">
              <div class="text-group_13 flex-col justify-between">
                <span class="text_28">{{lang === 't'  ?'願景' : '愿景'}}</span>
                <span class="paragraph_4">
                  {{lang === 't'  ?'團隊協作為基礎' : '团队协作为基础'}}&nbsp;
                  <br />
                  {{lang === 't'  ?'卓越管理為核心' : '卓越管理为核心'}}
                  <br />
                  {{lang === 't'  ?'量身定製為特色' : '量身定制为特色'}}&nbsp;
                  <br />
                  {{lang === 't'  ?'金融賦能為目標' : '金融赋能为目标'}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="group_19 flex-row">
          <div class="text-wrapper_18 flex-col justify-between">
            <span class="text_29">{{lang === 't'  ?'快速鏈接' : '快速链接'}}</span>
            <span class="paragraph_5">
              <p class="txt" @click="$router.push({path:'/index'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
              <p class="txt" @click="$router.push({path:'/news'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
              <p class="txt" @click="$router.push({path:'/features'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/team'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
              <p class="txt" @click="$router.push({path:'/market'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
            </span>
          </div>
          <div class="box_15 flex-col">
            <span class="text_33">公司地址</span>
            <span class="text_30">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
            <!-- <div class="text-group_14 flex-col justify-between">
              <span class="text_31">{{lang === 't'  ?'聯系方式' : '联系方式'}}</span>
              <span class="text_32">Tel:  +86 17796356225</span>
            </div> -->
          </div>
          <img
            class="image_4"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGb36bb80c676d7b4c8778e245fd0d00e9.png"
          />
          <div class="box_16 flex-col justify-between">
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG2185665b7a2678d6670dbf33e6d75422.png"
            />
            <div class="image-text_6 flex-row justify-between">
              <span class="text-group_6" :class="{show: show}" @click="showLang">
                {{lang === 't' ? '繁体中文' :  '简体中文'}}
                <div class="drag-down">
                  <p class="trans" @click="changeLan('t')">繁体中文</p>
                  <p  @click="changeLan('s')" >简体中文</p>
                </div>
              </span>
              <img
                @click="showLang"
                class="label_3"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNGc64669b925aa71f0312800b59acb269e.png"
              />
            </div>
          </div>
        </div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG60e718d793155daa0424a234eb32e610.png"
        />
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="./assets/img/MasterDDSSlicePNG6981cf0253d63a5b0d2271f90dc7e91c.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: false,
    };
  },
  methods: {
    showLang() {
      this.show = !this.show
    },
    changeLan(t) {
      window.localStorage.setItem('lang', t);
      window.location.reload();
    },
  },
  created() {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.$router.push({path: '/index_mobile'})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />