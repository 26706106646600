<template>
  <div class="page flex-col" :style="{height: height}">
    <div class="box_1 flex-col">
      <div class="section_1 flex-col">
        <div class="nav-bar_2 flex-row" :class="{black: isBlackTop}">
          <div class="group_13 flex-col">
            <div class="image-wrapper_2 flex-row justify-between">
              <img
                class="icon_1"
              @click="showSlider"
                referrerpolicy="no-referrer"
                src="./assets/img/MasterDDSSlicePNG0d956aa7ed8e338a0078ca75fb8e3c13.png"
              />
              <img
                class="image_1"
                referrerpolicy="no-referrer"
                src="./assets/img/logo.png"
              />
            </div>
          </div>
          <div class="group_14 flex-col justify-between">
            <div class="section_2 flex-col"></div>
            <div class="section_3 flex-col"></div>
          </div>
        </div>
        <span class="text_1">          {{lang === 't' ? '上鼎新聞動態':'上鼎新闻动态'}}
        </span>
        <span class="text_2">          {{lang === 't' ? '最新動態發展，最新新聞資訊，精彩內容盡收眼底。':'最新动态发展，最新新闻资讯，精彩内容尽收眼底。'}}</span>
      </div>
      <div class="section_5 flex-col" :style="{height: height2}">
        <span class="text_8">新闻动态</span>
        <div class="group_3 flex-col"></div>
        <div class="box_6 flex-row" @click="gotoDetail">
          <span class="text_9">06/</span>
          <span class="paragraph_2">
            08月
            <br />
            2024
          </span>
          <div class="text-wrapper_7 flex-col justify-between">
            <span class="text_10">{{lang === 't' ? '上鼎國際與美國上市公司達成合作':'上鼎国际与美国上市公司达成合作'}}</span>
            <span class="text_11">
              {{lang === 't' ? '經過公司管理層與美國上市公司的多次深入溝通，雙方已正式簽署合作意向書，標誌着合作關係的正式確立。同時...。':'经过公司管理層与美国上市公司的多次深入沟通，双方已正式签署合作意向书，标志着合作关系的正式确立。同时...'}}
            </span>
          </div>
        </div>
        <div class="box_6 flex-row" @click="gotoDetail2">
          <span class="text_9">12/</span>
          <span class="paragraph_2">
            08月
            <br />
            2024
          </span>
          <div class="text-wrapper_7 flex-col justify-between">
            <span class="text_10">
              {{lang === 't' ? '上鼎國際與美國上市公司正式達成全面...':'上鼎国际与美国上市公司正式达成全面...'}}
              
            </span>
            <span class="text_11">
              {{lang === 't' ? '香港上鼎人工智能有限公司已經跟美國上市公司簽署合作協議，這一里程碑式的合作...':'香港上鼎人工智能有限公司已经跟美国上市公司签署合作协议，这一里程碑式的合作...'}}
            </span>
          </div>
        </div>
        <!-- <div class="box_12 flex-row">
          <div class="icon_2 flex-col"><div class="section_6 flex-col"></div></div>
          <div class="text-wrapper_13 flex-col"><span class="text_28">1</span></div>
          <div class="text-wrapper_14 flex-col"><span class="text_29">2</span></div>
          <div class="text-wrapper_15 flex-col"><span class="text_30">3</span></div>
          <div class="text-wrapper_16 flex-col"><span class="text_31">...</span></div>
          <div class="text-wrapper_17 flex-col"><span class="text_32">9</span></div>
          <div class="icon_3 flex-col"><div class="box_13 flex-col"></div></div>
        </div> -->
        <div class="group_10 flex-col">
          <span class="text_27">最新资讯</span>
          <div class="group_11 flex-col"></div>
          <div class="group_12 flex-row justify-start" @click="gotoDetail2">
            <div class="box_3 flex-row">
              <div class="image-text_5 flex-col justify-between">
                <img
                  class="image_4"
                  referrerpolicy="no-referrer"
                  src="./assets/img/MasterDDSSlicePNGb305d09c7ded8dd888e3a655f9a57019.png"
                />
                <span class="text-group_3"> 
                {{lang === 't' ? '上鼎國際與美國上市公司正式達成全面戰略合作協議':'上鼎国际与美国上市公司正式达成全面战略合作协议'}}

                </span>
              </div>
            </div>
            <div class="box_4 flex-row"  @click="gotoDetail">
              <div class="image-text_6 flex-col justify-between">
                <img
                  class="image_5"
                  referrerpolicy="no-referrer"
                  src="./assets/img/MasterDDSSlicePNG71b4dbb78a483b8f8d61b2d63f2eeeaf.png"
                />
                <span class="text-group_4">  
                  {{lang === 't' ? '上鼎國際與美國上市公司達成合作':'上鼎国际与美国上市公司达成合作'}}
                  
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_4 flex-col">
        <div class="group_15 flex-row">
          <!-- <span class="text_3">快速链接</span> -->
          <span class="text_4">公司地址</span>
          <!-- <div class="image-text_4 flex-row justify-between">
            <span class="text-group_1">简繁切换</span>
            <img
              class="label_1"
              referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG4fe04a52f00f13adbbcc5eac12de33bb.png"
            />
          </div> -->
        </div>
        <div class="group_16 flex-row">
          <!-- <span class="paragraph_1">
            关于我们
            <br />
            业务介绍
            <br />
            团队介绍
            <br />
            国际市场
          </span> -->
          <div class="box_5 flex-col justify-between">
            <span class="text_5">{{lang === 't'  ?'香港中環幹諾道中34-37 號華懋大廈 17樓1701室' : '香港中环干诺道中34-37 号华懋大厦 17楼1701室'}}</span>
            <!-- <div class="text-group_5 flex-col justify-between">
              <span class="text_6">联系方式</span>
              <span class="text_7">Tel:&nbsp;&nbsp;+86&nbsp;17796356225</span>
            </div> -->
          </div>
          <!-- <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG49943e02bfbfa19e3901d302d72cfe2f.png"
          />
          <img
            class="image_3"
            referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGffd7ebe928f93bb34d6e1e811f1ce2e0.png"
          /> -->
        </div>
      </div>
    <div class="slider_bg" :class="show === null ? '' : show=== false ? 'hide': 'show'" @click="showSlider"></div>
    <div class="slider-list" :class="show === null ? '' : show=== false ? 'hide': 'show'">
      <div class="close" @click="showSlider"></div>
      <p class="txt" @click="$router.push({path:'/index_mobile'})">{{lang === 't'  ?'關於我們' : '关于我们'}}</p>
      <p class="txt" @click="$router.push({path:'/news_mobile'})">{{lang === 't'  ?'新聞資訊' : '新闻资讯'}}</p>
      <p class="txt" @click="$router.push({path:'/features_mobile'})">{{lang === 't'  ?'業務介紹' : '业务介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/team_mobile'})">{{lang === 't'  ?'團隊介紹' : '团队介绍'}}</p>
      <p class="txt" @click="$router.push({path:'/market_mobile'})">{{lang === 't'  ?'國際市場' : '国际市场'}}</p>
      <div class="lang" @click="changeLan">简体 ｜ 繁体</div>
    </div>
  </div>
</template>
<script>
const num = 2; // 新闻数  此页面搜 box_6
export default {
  data() {
    return {
      lang: window.localStorage.getItem('lang') || 't',
      show: null,
      isBlackTop: false,
      height: (52 + num*3.11) + 'rem',
      height2: (52 + num*3.11) + 'rem',
    };
  },
  methods: {
    showSlider() {
      this.show = !this.show
    },
    // changeLan(t) {
    //   window.localStorage.setItem('lang', t);
    //   window.location.reload();
    // },
       changeLan() {
        const lang = window.localStorage.getItem('lang')
        window.localStorage.setItem('lang', lang === 't' ? 's': 't');
        window.location.reload();
    },
     resetFontSize() {
        const size = (document.documentElement.clientWidth / 1080) * 37.5;
        document.documentElement.style.fontSize = size + 'px';
    },
    onScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 80) {
        this.isBlackTop = true
      } else {
        this.isBlackTop = false
      }
    },
    gotoDetail() {
    this.$router.push({path: '/news_mobile_detail'})
    },
    gotoDetail2() {
    this.$router.push({path: '/news_mobile_detail2'})
    }
  },
  created() {
  // reset root font size on page show or resize
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.addEventListener('pageshow', this.resetFontSize);
    window.addEventListener('resize', this.resetFontSize);
    window.addEventListener('scroll', this.onScroll);
  } else {
    this.$router.push({path: '/news'})
  }
  }
};
</script>
<style scoped lang="css" src="./assets/index.rem.css" />